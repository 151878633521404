<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Acenta Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Acenta Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Acenta Listesi",
          href: "/agency",
        },
        {
          text: "Acenta Güncelleme",
          active: true,
        },
      ],
      newAgency: {
        company_name: "",
        official_name: "",
        official_phone: "",
        company_address: "",
        company_email: "",
        company_tax: "",
        company_tax_number: "",
        isActive: "",
      },
      isActiveOptions: [
        { value: 1, text: "Aktif" },
        { value: 0, text: "Aktif Değil" },
      ],
    };
  },
  mounted() {
    const agencyId = this.$route.params.id;
    const api_url = process.env.VUE_APP_BASEURL + `/agency/${agencyId}`;

    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.newAgency = response.data?.data; // Get ile gelen verileri form alanlarına doldur
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    updateAgency() {
      const agencyId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/agency/${agencyId}`;

      axios
        .put(api_url, this.newAgency, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/agency");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="updateAgency">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_name">Şirket Adı:</label>
                    <input
                      v-model="newAgency.company_name"
                      type="text"
                      class="form-control"
                      id="company_name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="official_name">Resmi Adı:</label>
                    <input
                      v-model="newAgency.official_name"
                      type="text"
                      class="form-control"
                      id="official_name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="official_phone">Resmi Numara:</label>
                    <input
                      v-model="newAgency.official_phone"
                      type="text"
                      class="form-control"
                      id="official_phone"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_address">Şirket Adresi:</label>
                    <input
                      v-model="newAgency.company_address"
                      type="text"
                      class="form-control"
                      id="company_address"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_email">Şirket Maili:</label>
                    <input
                      v-model="newAgency.company_email"
                      type="text"
                      class="form-control"
                      id="company_email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_tax">Şirket Vergisi:</label>
                    <input
                      v-model="newAgency.company_tax"
                      type="text"
                      class="form-control"
                      id="company_tax"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_tax_number">Şirket Vergi Numarası:</label>
                    <input
                      v-model="newAgency.company_tax_number"
                      type="text"
                      class="form-control"
                      id="company_tax_number"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="isActive">Aktif Durumu:</label>
                    <b-form-select
                      v-model="newAgency.isActive"
                      :options="isActiveOptions"
                      class="form-control"
                      id="isActive"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Güncelle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
